import React, { useState, useEffect } from 'react';

const Hints = ({ setHintSeenAppTrue, win }) => {
  const [hintsButtonStatus, setHintsButtonStatus] = useState(false);
  const [hintSelected, setHintSelected] = useState(false);

  useEffect(() => {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var currentTime = new Date();
    if (hintsButtonStatus) {
      fetch('/hint', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ timezone, currentTime }),
      })
        .then((result) => result.json())
        .then(({ hintServer }) => setHintSelected(hintServer))
        .then(() => {
          if (win === false) {
            localStorage.setItem('hintSeen', true);
            setHintSeenAppTrue();
          }
        })
        .catch((err) => {
          console.log(err);
          setHintsButtonStatus(false);
          localStorage.setItem('hintSeen', false);
        });
    } else {
      let hintSeenLS = localStorage.getItem('hintSeen');
      if (hintSeenLS)
        if (hintSeenLS !== 'false') {
          setHintsButtonStatus(true);
        }
    }
  }, [hintsButtonStatus]);

  const hintsButtonClicked = () => {
    setHintsButtonStatus(true);
  };

  return (
    <div className="hintsContainer">
      <div className={hintsButtonStatus ? 'hideHintsButtonContainer' : 'hintsButtonContainer'}>
        <button className="showHintsButton" type="button" onClick={hintsButtonClicked}>
          Show Hint
        </button>
      </div>
      <div className={hintsButtonStatus ? 'hintsTextContainer' : 'hideHintsTextContainer'}>
        <p>
          <strong>Hint</strong>
        </p>
        <p>{hintSelected ? hintSelected : 'No hint yet'}</p>
      </div>
    </div>
  );
};

export { Hints };
