import React, { useState, useEffect } from 'react';

const keyboardButtonsNamesArray1 = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
const keyboardButtonsNamesArray2 = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'];
const keyboardButtonsNamesArray3 = [
  'ENTER',
  'Z',
  'X',
  'C',
  'V',
  'B',
  'N',
  'M',
  <i className="fa-solid fa-delete-left"></i>,
];

const Keyboard = (props) => {
  const physicalKeyboardInput = (event) => {
    const physicalKeyPressed = event.key.toUpperCase();
    if (physicalKeyPressed.length === 1) {
      if (physicalKeyPressed >= 'A' && physicalKeyPressed <= 'Z') {
        props.buttonClickedMainBody(physicalKeyPressed);
      }
    } else {
      if (physicalKeyPressed === 'BACKSPACE') props.buttonClickedMainBody(physicalKeyPressed);
      else if (physicalKeyPressed === 'DELETE') props.buttonClickedMainBody(physicalKeyPressed);
      else if (physicalKeyPressed === 'ENTER') props.buttonClickedMainBody(physicalKeyPressed);
    }
  };
  useEffect(() => {
    if (!props.disableButton) {
      document.addEventListener('keydown', physicalKeyboardInput);
      if (props.modalOnNavbar) {
        document.removeEventListener('keydown', physicalKeyboardInput);
      }
    } else {
      document.removeEventListener('keydown', physicalKeyboardInput);
    }

    return () => {
      document.removeEventListener('keydown', physicalKeyboardInput);
    };
  }, [props.disableButton, props.modalOnNavbar]);

  return (
    <div className="keyboard">
      <div className="keyboardRow1">
        {keyboardButtonsNamesArray1.map((keyboardButtonName, index) => {
          const buttonClickedKeyboard = () => {
            props.buttonClickedMainBody(keyboardButtonName);
          };
          return (
            <KeyboardButton
              key={index}
              keyboardButtonName={keyboardButtonName}
              buttonClickedKeyboard={buttonClickedKeyboard}
              disableButton={props.disableButton}
              keyboardButtonsColor={props.keyboardButtonsColor}
            />
          );
        })}
      </div>
      <div className="keyboardRow2">
        {keyboardButtonsNamesArray2.map((keyboardButtonName, index) => {
          const buttonClickedKeyboard = () => {
            props.buttonClickedMainBody(keyboardButtonName);
          };
          return (
            <KeyboardButton
              key={index}
              keyboardButtonName={keyboardButtonName}
              buttonClickedKeyboard={buttonClickedKeyboard}
              disableButton={props.disableButton}
              keyboardButtonsColor={props.keyboardButtonsColor}
            />
          );
        })}
      </div>
      <div className="keyboardRow3">
        {keyboardButtonsNamesArray3.map((keyboardButtonName, index) => {
          const buttonClickedKeyboard = () => {
            if (index === 8) props.buttonClickedMainBody('DELETE');
            else props.buttonClickedMainBody(keyboardButtonName);
          };
          return (
            <KeyboardButton
              key={index}
              keyboardButtonName={keyboardButtonName}
              buttonClickedKeyboard={buttonClickedKeyboard}
              disableButton={props.disableButton}
              keyboardButtonsColor={props.keyboardButtonsColor}
              ifDeleteButton={index === 8 ? 'DELETE' : ''}
            />
          );
        })}
      </div>
    </div>
  );
};

const KeyboardButton = ({
  keyboardButtonName,
  buttonClickedKeyboard,
  disableButton,
  keyboardButtonsColor,
  ifDeleteButton,
}) => {
  const [keyboardButtonColor, setKeyboardButtonColor] = useState(false);
  useEffect(() => {
    if (keyboardButtonColor !== 'CORRECT' && keyboardButtonColor !== 'WRONG') {
      let keyboardButtonColortemp;
      keyboardButtonsColor.forEach((keyboardButtonColor) => {
        if (keyboardButtonColor.name === keyboardButtonName) {
          if (keyboardButtonColortemp !== 'CORRECT') {
            if (keyboardButtonColortemp !== 'ALMOSTCORRECT') {
              keyboardButtonColortemp = keyboardButtonColor.color;
            } else {
              if (keyboardButtonColor.color === 'CORRECT') keyboardButtonColortemp = 'CORRECT';
            }
          }
        }
      });
      if (keyboardButtonColortemp) setKeyboardButtonColor(keyboardButtonColortemp);
    }

    // return () => {
    //   second
    // }
  }, [keyboardButtonsColor]);

  return (
    <button
      type="button"
      className={`keyboardLetterButton${
        keyboardButtonColor
          ? keyboardButtonColor === 'CORRECT'
            ? ' keyboardButtonColorCorrect'
            : keyboardButtonColor === 'ALMOSTCORRECT'
            ? ' keyboardButtonColorAlmostCorrect'
            : keyboardButtonColor === 'WRONG'
            ? ' keyboardButtonColorWrong'
            : ''
          : ''
      }${keyboardButtonName === 'ENTER' ? ' keyboardButtonEnter' : ''}${
        ifDeleteButton === 'DELETE' ? ' keyboardButtonDelete' : ''
      }`}
      onClick={() => {
        !disableButton && buttonClickedKeyboard();
      }}
    >
      {keyboardButtonName}
    </button>
  );
};

export { Keyboard };
