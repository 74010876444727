import React, { useEffect, useState } from 'react';

const Modals = ({ modalCommence, modalCommenceMainBody, win }) => {
  const [rightWordModal, setRightWordModal] = useState(false);
  const [wordSelected, setWordSelected] = useState(false);
  useEffect(() => {
    if (modalCommence !== false)
      setTimeout(() => {
        modalCommenceMainBody(false);
      }, 3000);
  });

  useEffect(() => {
    if (localStorage.getItem('gameOver')) {
      let gameOverLS = JSON.parse(localStorage.getItem('gameOver'));
      if (gameOverLS.value === 1 && win === 0) setRightWordModal(true);
    }
  }, [win]);

  useEffect(() => {
    if (rightWordModal) {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var currentTime = new Date();
      fetch('/word', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ timezone, currentTime }),
      })
        .then((result) => result.json())
        .then(({ wordSelectedServer }) => setWordSelected(wordSelectedServer))
        .catch((err) => console.log(err));
    }
  }, [rightWordModal]);

  if (rightWordModal) {
    return (
      <div className="rightWordModal">
        <p>{wordSelected}</p>
      </div>
    );
  }
  if (modalCommence === 'WRONG')
    return (
      <div className="modalCommence">
        <p>Not present in the word list</p>
      </div>
    );
  else if (modalCommence === 0)
    return (
      <div className="modalCommence">
        <p>Miraculous</p>
      </div>
    );
  else if (modalCommence === 1)
    return (
      <div className="modalCommence">
        <p>Blessed</p>
      </div>
    );
  else if (modalCommence === 2)
    return (
      <div className="modalCommence">
        <p>Spirit Led</p>
      </div>
    );
  else if (modalCommence === 3)
    return (
      <div className="modalCommence">
        <p>10 talented servant</p>
      </div>
    );
  else if (modalCommence === 4)
    return (
      <div className="modalCommence">
        <p>Faithful</p>
      </div>
    );
  else if (modalCommence === 5)
    return (
      <div className="modalCommence">
        <p>God's grace</p>
      </div>
    );
  else return <></>;
};

export { Modals };
