import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app';

var currentTime = new Date();
var yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

if (localStorage.getItem('version') === '4') {
  if (localStorage.getItem('currentDate')) {
    let dateStoredLS = new Date(localStorage.getItem('currentDate'));
    if (dateStoredLS.toDateString() !== currentTime.toDateString()) {
      localStorage.removeItem('gridValue');
      localStorage.removeItem('gameOver');
      localStorage.removeItem('keyboardButtonsColorLS');
      localStorage.removeItem('fromLS');
      localStorage.removeItem('hintSeen');
      localStorage.setItem('currentDate', currentTime);
    }
  } else {
    localStorage.removeItem('gridValue');
    localStorage.removeItem('gameOver');
    localStorage.removeItem('keyboardButtonsColorLS');
    localStorage.removeItem('fromLS');
    localStorage.removeItem('hintSeen');
    localStorage.setItem('currentDate', currentTime);
  }
  if (!localStorage.getItem('stats')) {
    localStorage.setItem(
      'stats',
      JSON.stringify({
        GC: 0,
        GW: 0,
        CS: 0,
        HS: 0,
        noOf1: 0,
        noOf2: 0,
        noOf3: 0,
        noOf4: 0,
        noOf5: 0,
        noOf6: 0,
        hints: 0,
        dateUpdated: currentTime,
      })
    );
  } else {
    let statsLS = localStorage.getItem('stats');
    statsLS = JSON.parse(statsLS);
    let dateUpdatedLS = new Date(statsLS.dateUpdated);
    if (
      dateUpdatedLS.toDateString() !== yesterday.toDateString() &&
      dateUpdatedLS.toDateString() !== currentTime.toDateString()
    ) {
      statsLS.CS = 0;
      localStorage.setItem('stats', JSON.stringify(statsLS));
    }
  }
} else {
  localStorage.removeItem('gridValue');
  localStorage.removeItem('gameOver');
  localStorage.removeItem('keyboardButtonsColorLS');
  localStorage.removeItem('fromLS');
  localStorage.removeItem('hintSeen');
  localStorage.setItem('currentDate', currentTime);
  localStorage.setItem('version', 4);
  if (!localStorage.getItem('stats')) {
    localStorage.setItem(
      'stats',
      JSON.stringify({
        GC: 0,
        GW: 0,
        CS: 0,
        HS: 0,
        noOf1: 0,
        noOf2: 0,
        noOf3: 0,
        noOf4: 0,
        noOf5: 0,
        noOf6: 0,
        hints: 0,
        dateUpdated: currentTime,
      })
    );
  } else {
    let statsLS = localStorage.getItem('stats');
    statsLS = JSON.parse(statsLS);
    if (!statsLS.hasOwnProperty('hints')) {
      statsLS.hints = 0;
      localStorage.setItem('stats', JSON.stringify(statsLS));
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
