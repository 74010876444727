import React, { useState, useEffect, useRef } from 'react';

const ShareModal = ({
  shareModalState,
  setShareModalState,
  setShareModalStateTrue,
  win,
  shareWin,
  setShareMessageModalStateTrue,
  setShareMessageModalStateFalse,
  setNewBiblidleMessageModalStateTrue,
  hintSeenApp,
}) => {
  const [wordSelected, setWordSelected] = useState(false);
  const [timeLeft, setTimeLeft] = useState({ hours: '00', minutes: '00', seconds: '00' });

  const refModalCloseAnimation = useRef(null);
  const modalCloseFunction = () => {
    let fadeOutAnimationInterval = null;
    let opacityTemp = 1;
    clearInterval(fadeOutAnimationInterval);
    const fadeOutAnimation = () => {
      if (opacityTemp < 0.1) {
        clearInterval(fadeOutAnimationInterval);
        setShareModalState();
      } else {
        opacityTemp = opacityTemp - 0.1;
        refModalCloseAnimation.current.style.opacity = opacityTemp;
      }
    };
    fadeOutAnimationInterval = setInterval(fadeOutAnimation, 20);
  };
  const ifOutsideModalCloseFunction = (e) => {
    if (e.target === refModalCloseAnimation.current) modalCloseFunction();
  };

  // useEffect(() => {
  //   let fromLS = localStorage.getItem('fromLS');
  //   if (fromLS) {
  //     setShareModalStateTrue();
  //   } else {
  //     if (win !== false && win !== 0) {
  //       setTimeout(() => {
  //         setShareModalStateTrue();
  //       }, 3000);
  //     } else if (win === 0) {
  //       setShareModalStateTrue();
  //     }
  //   }
  // }, [win]);

  useEffect(() => {
    const timerFunction = setInterval(() => {
      let timerCurrentTimeStart = new Date();
      let timerCurrentTimeStartms = timerCurrentTimeStart.getTime();
      // console.log(timerCurrentTimeStart.toISOString());
      // console.log(timerCurrentTimeStartms);

      let timerTimeEnd = new Date(
        parseInt(timerCurrentTimeStart.getFullYear()),
        parseInt(timerCurrentTimeStart.getMonth()),
        parseInt(timerCurrentTimeStart.getDate() + 1)
      );

      let timerTimeEndms = timerTimeEnd.getTime();
      // console.log(timerTimeEnd.toISOString());
      // console.log(timerTimeEndms)

      let timerTimeLeftms = timerTimeEndms - timerCurrentTimeStartms;
      // console.log(timerTimeLeftms);
      let timerTimeLefthours = Math.floor(
        (timerTimeLeftms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let timerTimeLeftminutes = Math.floor((timerTimeLeftms % (1000 * 60 * 60)) / (1000 * 60));
      let timerTimeLeftseconds = Math.floor((timerTimeLeftms % (1000 * 60)) / 1000);
      if (timerTimeLefthours <= 9) timerTimeLefthours = `0${timerTimeLefthours}`;
      if (timerTimeLeftminutes <= 9) timerTimeLeftminutes = `0${timerTimeLeftminutes}`;
      if (timerTimeLeftseconds <= 9) timerTimeLeftseconds = `0${timerTimeLeftseconds}`;
      // if (timerTimeLeftms >= 86399000) {
      //   setNewBiblidleMessageModalStateTrue();
      // }
      setTimeLeft({
        hours: timerTimeLefthours,
        minutes: timerTimeLeftminutes,
        seconds: timerTimeLeftseconds,
      });
    }, 1000);

    return () => clearInterval(timerFunction);
  });

  useEffect(() => {
    if (win !== false) {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var currentTime = new Date();
      fetch('/word', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ timezone, currentTime }),
      })
        .then((result) => result.json())
        .then(({ wordSelectedServer }) => setWordSelected(wordSelectedServer))
        .catch((err) => console.log(err));
    }
  }, [win]);

  const [shareWinDataSize, setShareWinDataSize] = useState(false);
  const refShareWinData = useRef(null);
  const refShareModal = useRef(null);

  useEffect(() => {
    // console.log('window:', window.innerWidth);
    // console.log('refShareWinData:', refShareWinData.current.offsetWidth);
    if (shareModalState === true && win !== false) {
      if (refShareWinData.current.clientWidth < refShareWinData.current.scrollWidth) {
        if (shareWinDataSize === false) setShareWinDataSize(5);
        else setShareWinDataSize(shareWinDataSize - 1);
      }
      // console.log(inputAnswerWindowSize);
    }
  });

  let shareWinDataTextNoFalse =
    'Play Christian Wordle https://biblidle.bibledailythoughts.com #biblidle';
  const shareWinData = [];
  let shareWinDataTextYes = '';
  if (win !== false) {
    shareWinDataTextYes = shareWinDataTextYes + `Biblidle ${win}/6\n`;
    let winTemp;
    if (win === 0) winTemp = 6;
    else winTemp = win;
    for (let i = 0; i < winTemp; i++) {
      let shareWinDataTemp = [];
      for (let j = 0; j < wordSelected.length; j++) {
        if (shareWin[i * wordSelected.length + j].class === 'inputLetterCorrect') {
          shareWinDataTemp.push(
            <p
              key={j}
              style={
                isNaN(shareWinDataSize)
                  ? {}
                  : {
                      fontSize: `calc(0.4rem + ${shareWinDataSize}vmin)`,
                    }
              }
            >
              🟩
            </p>
          );
          shareWinDataTextYes = shareWinDataTextYes + '🟩';
        } else if (shareWin[i * wordSelected.length + j].class === 'inputLetterAlmostCorrect') {
          shareWinDataTemp.push(
            <p
              key={j}
              style={
                isNaN(shareWinDataSize)
                  ? {}
                  : {
                      fontSize: `calc(0.4rem + ${shareWinDataSize}vmin)`,
                    }
              }
            >
              🟨
            </p>
          );
          shareWinDataTextYes = shareWinDataTextYes + '🟨';
        } else if (shareWin[i * wordSelected.length + j].class === 'inputLetterWrong') {
          shareWinDataTemp.push(
            <p
              key={j}
              style={
                isNaN(shareWinDataSize)
                  ? {}
                  : {
                      fontSize: `calc(0.4rem + ${shareWinDataSize}vmin)`,
                    }
              }
            >
              ⬛
            </p>
          );
          shareWinDataTextYes = shareWinDataTextYes + '⬛';
        } else {
          shareWinDataTemp.push(
            <p
              key={j}
              style={
                isNaN(shareWinDataSize)
                  ? {}
                  : {
                      fontSize: `calc(0.4rem + ${shareWinDataSize}vmin)`,
                    }
              }
            >
              ⬜
            </p>
          );
          shareWinDataTextYes = shareWinDataTextYes + '⬜';
        }
      }
      shareWinDataTextYes = shareWinDataTextYes + '\n';
      shareWinData.push(
        <div className="shareWinData" key={i}>
          {shareWinDataTemp}
        </div>
      );
    }
    if (hintSeenApp) shareWinDataTextYes = shareWinDataTextYes + 'Hint used : Yes\n';
    else shareWinDataTextYes = shareWinDataTextYes + 'Hint used : No\n';
    shareWinDataTextYes = shareWinDataTextYes + 'https://biblidle.bibledailythoughts.com #biblidle';
  }

  const shareButtonWinYesPromise = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(shareWinDataTextYes);
    return Promise.reject('The Clipboard API is not available.');
  };

  const shareButtonWinNoFalsePromise = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(shareWinDataTextNoFalse);
    return Promise.reject('The Clipboard API is not available.');
  };

  const shareButtonWinYes = async () => {
    try {
      await shareButtonWinYesPromise();
      setShareMessageModalStateTrue();
    } catch (err) {
      setShareMessageModalStateFalse();
    }
  };

  const shareButtonWinNoFalse = async () => {
    try {
      await shareButtonWinNoFalsePromise();
      setShareMessageModalStateTrue();
    } catch (err) {
      setShareMessageModalStateFalse();
    }
  };

  return (
    shareModalState && (
      <div
        className="shareModalContainer"
        ref={refModalCloseAnimation}
        onClick={ifOutsideModalCloseFunction}
      >
        <div className="shareModal" ref={refShareModal}>
          <div className="shareModalClose">
            <button type="button" onClick={modalCloseFunction}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="bodyShareModal">
            {win !== false ? (
              win !== 0 ? (
                <div className="winYes">
                  <p>Congrats you got today's Biblidle</p>
                  <p>Here are your results to share with everyone</p>
                  <div className="shareWinDataContainer" ref={refShareWinData}>
                    {shareWinData}
                  </div>
                  <p className="hintUsedShareModal">
                    {hintSeenApp ? 'Hint used: Yes' : 'Hint used: No'}
                  </p>
                </div>
              ) : (
                <div className="winNo">
                  <p>The right answer is </p>
                  <p className="winNoCorrectWord">{wordSelected}</p>
                  <p>Here are your results to share with everyone</p>
                  <div className="shareWinDataContainer" ref={refShareWinData}>
                    {shareWinData}
                  </div>
                  <p className="hintUsedShareModal">
                    {hintSeenApp ? 'Hint used: Yes' : 'Hint used: No'}
                  </p>
                </div>
              )
            ) : (
              <div className="winFalse">
                <p>Please share this game with everyone</p>
                <p>You can share your results after completing and winning the game</p>
              </div>
            )}
            {win !== false ? (
              <button type="button" onClick={shareButtonWinYes}>
                Share
              </button>
            ) : (
              <button type="button" onClick={shareButtonWinNoFalse}>
                Share
              </button>
            )}
            <p className="shareModalTimer">
              Next Biblidle in: {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export { ShareModal };
