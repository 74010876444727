import React, { useState, useEffect, useRef } from 'react';

const InputAnswer = (props) => {
  const [inputCellIDRow, setInputCellIDRow] = useState(0); //Row of input grid main
  const [inputCellIDCol, setInputCellIDCol] = useState(0); //Column of input grid main
  const [inputGridValue, setInputGridValue] = useState([]);

  const [inputAnswerWindowSize, setInputAnswerWindowSize] = useState(false);
  const refInputAnswer = useRef(null);

  useEffect(() => {
    if (props.resizeInputAnswer) {
      if (
        refInputAnswer.current.offsetWidth > 425 ||
        refInputAnswer.current.offsetWidth > window.innerWidth
      ) {
        if (inputAnswerWindowSize === false) setInputAnswerWindowSize(5);
        else setInputAnswerWindowSize(inputAnswerWindowSize - 1);
      }
    }
    return () => props.setResizeInputAnswerFalse();
  });

  useEffect(() => {
    let inputGridValueTemp = [];
    if (props.wordSelectedLength) {
      let gridValueLS = localStorage.getItem('gridValue');
      if (gridValueLS) {
        let gameOverLS = localStorage.getItem('gameOver');
        gameOverLS = JSON.parse(gameOverLS);
        let existingLSKeyboardButtonsColor = localStorage.getItem('keyboardButtonsColorLS');
        existingLSKeyboardButtonsColor = JSON.parse(existingLSKeyboardButtonsColor);
        setInputGridValue(JSON.parse(gridValueLS));
        props.keyboardButtonsColorMainBody(existingLSKeyboardButtonsColor);
        if (gameOverLS.value === 1) {
          localStorage.setItem('fromLS', 1);
          props.disableButtonMainBodyTrue();
          props.setWinApp(gameOverLS.winRow);
          props.setShareWinApp(JSON.parse(gridValueLS));
        } else if (gameOverLS.value === 0) {
          setInputCellIDRow(gameOverLS.winRow);
        }
      } else {
        for (let i = 0; i < 6; i++)
          for (let j = 0; j < props.wordSelectedLength; j++) {
            inputGridValueTemp.push({ row: i, col: j, value: '', class: '' });
          }
        setInputGridValue(inputGridValueTemp);
      }
    }
  }, [props.wordSelectedLength]);

  useEffect(() => {
    if (
      props.buttonClicked !== false &&
      props.buttonClicked !== 'ENTER' &&
      props.buttonClicked !== 'DELETE' &&
      props.buttonClicked !== 'BACKSPACE'
    ) {
      if (
        inputCellIDCol === props.wordSelectedLength - 1 &&
        inputGridValue[inputCellIDRow * props.wordSelectedLength + inputCellIDCol].value === ''
      ) {
        setInputGridValue((prevState) =>
          prevState.map((prevStateSingle, index) => {
            if (index === inputCellIDRow * props.wordSelectedLength + inputCellIDCol)
              prevStateSingle.value = props.buttonClicked;
            return prevStateSingle;
          })
        );
      } else if (inputCellIDCol !== props.wordSelectedLength - 1) {
        setInputGridValue((prevState) =>
          prevState.map((prevStateSingle, index) => {
            if (index === inputCellIDRow * props.wordSelectedLength + inputCellIDCol)
              prevStateSingle.value = props.buttonClicked;
            return prevStateSingle;
          })
        );
        setInputCellIDCol(inputCellIDCol + 1);
      }
    } else if (
      props.buttonClicked !== false &&
      props.buttonClicked !== 'ENTER' &&
      (props.buttonClicked === 'DELETE' || props.buttonClicked === 'BACKSPACE')
    ) {
      if (inputGridValue[inputCellIDRow * props.wordSelectedLength + inputCellIDCol].value !== '') {
        setInputGridValue((prevState) =>
          prevState.map((prevStateSingle, index) => {
            if (index === inputCellIDRow * props.wordSelectedLength + inputCellIDCol)
              prevStateSingle.value = '';
            return prevStateSingle;
          })
        );
      } else if (
        inputCellIDCol === 0 &&
        inputGridValue[inputCellIDRow * props.wordSelectedLength + inputCellIDCol].value === ''
      ) {
      } else {
        setInputGridValue((prevState) =>
          prevState.map((prevStateSingle, index) => {
            if (index === inputCellIDRow * props.wordSelectedLength + inputCellIDCol - 1)
              prevStateSingle.value = '';
            return prevStateSingle;
          })
        );
        setInputCellIDCol(inputCellIDCol - 1);
      }
    } else if (
      props.buttonClicked !== false &&
      props.buttonClicked === 'ENTER' &&
      props.buttonClicked !== 'DELETE' &&
      props.buttonClicked !== 'BACKSPACE'
    ) {
      // document.removeEventListener('keydown', props.physicalKeyboardInput);
      props.disableButtonMainBodyTrue();
      let userInputWord = '';
      for (
        let i = inputCellIDRow * props.wordSelectedLength;
        i <= inputCellIDRow * props.wordSelectedLength + inputCellIDCol;
        i++
      ) {
        userInputWord = userInputWord + inputGridValue[i].value;
      }
      // console.log('userInputWord:', userInputWord);
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var currentTime = new Date();
      fetch('/checkInputWord', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ userInputWord, timezone, currentTime }),
      })
        .then((result) => result.json())
        .then((checkInputWordData) => {
          let keyboardButtonsColor = [];
          const waitForInputLetterAnimation = () => {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(1);
              }, 500);
            });
          };
          const checkInputWordAsyncFunction = async () => {
            //check if word is exactly correct
            if (checkInputWordData.checkInputWord === 'CORRECT') {
              for (
                let i = inputCellIDRow * props.wordSelectedLength;
                i <= inputCellIDRow * props.wordSelectedLength + inputCellIDCol;
                i++
              ) {
                setInputGridValue((prevState) =>
                  prevState.map((prevStateSingle, index) => {
                    if (index === i) prevStateSingle.class = 'inputLetterCorrect';
                    return prevStateSingle;
                  })
                );
                keyboardButtonsColor.push({
                  name: inputGridValue[i].value,
                  color: 'CORRECT',
                });
                await waitForInputLetterAnimation();
              }
              localStorage.setItem(
                'gameOver',
                JSON.stringify({ value: 1, winRow: inputCellIDRow + 1 })
              );
              let statsLStemp = localStorage.getItem('stats');
              statsLStemp = JSON.parse(statsLStemp);
              if (inputCellIDRow === 0) {
                props.setWinApp(1);
                props.modalCommenceMainBody(0);
                statsLStemp.noOf1 = statsLStemp.noOf1 + 1;
              } else if (inputCellIDRow === 1) {
                props.setWinApp(2);
                props.modalCommenceMainBody(1);
                statsLStemp.noOf2 = statsLStemp.noOf2 + 1;
              } else if (inputCellIDRow === 2) {
                props.setWinApp(3);
                props.modalCommenceMainBody(2);
                statsLStemp.noOf3 = statsLStemp.noOf3 + 1;
              } else if (inputCellIDRow === 3) {
                props.setWinApp(4);
                props.modalCommenceMainBody(3);
                statsLStemp.noOf4 = statsLStemp.noOf4 + 1;
              } else if (inputCellIDRow === 4) {
                props.setWinApp(5);
                props.modalCommenceMainBody(4);
                statsLStemp.noOf5 = statsLStemp.noOf5 + 1;
              } else if (inputCellIDRow === 5) {
                props.setWinApp(6);
                props.modalCommenceMainBody(5);
                statsLStemp.noOf6 = statsLStemp.noOf6 + 1;
              }
              statsLStemp.GC = statsLStemp.GC + 1;
              statsLStemp.GW = statsLStemp.GW + 1;
              statsLStemp.CS = statsLStemp.CS + 1;
              if (statsLStemp.CS > statsLStemp.HS) statsLStemp.HS = statsLStemp.CS;
              if (props.hintSeenApp) statsLStemp.hints = statsLStemp.hints + 1;
              statsLStemp.dateUpdated = new Date();
              localStorage.setItem('stats', JSON.stringify(statsLStemp));

              localStorage.setItem('gridValue', JSON.stringify(inputGridValue));
              props.setShareWinApp(inputGridValue);
            }
            //check if word is part of word list
            else if (checkInputWordData.checkInputWord === 'ALMOSTCORRECT') {
              checkInputWordData.almostCorrectData.sort((a, b) => a.index - b.index);
              for (let j = 0; j < checkInputWordData.almostCorrectData.length; j++) {
                setInputGridValue((prevState) =>
                  prevState.map((prevStateSingle, index) => {
                    if (
                      index ===
                      inputCellIDRow * props.wordSelectedLength +
                        checkInputWordData.almostCorrectData[j].index
                    )
                      prevStateSingle.class = checkInputWordData.almostCorrectData[j].class;
                    return prevStateSingle;
                  })
                );
                await waitForInputLetterAnimation();
              }
              keyboardButtonsColor = checkInputWordData.almostCorrectData;
              localStorage.setItem('gridValue', JSON.stringify(inputGridValue));
              if (inputCellIDRow !== 5) {
                setInputCellIDRow(inputCellIDRow + 1);
                setInputCellIDCol(0);
                props.disableButtonMainBodyFalse();
                localStorage.setItem(
                  'gameOver',
                  JSON.stringify({ value: 0, winRow: inputCellIDRow + 1 })
                );
              } else {
                localStorage.setItem('gameOver', JSON.stringify({ value: 1, winRow: 0 }));
                props.setWinApp(0);
                props.setShareWinApp(inputGridValue);
                let statsLStemp = localStorage.getItem('stats');
                statsLStemp = JSON.parse(statsLStemp);
                statsLStemp.GC = statsLStemp.GC + 1;
                statsLStemp.CS = 0;
                if (props.hintSeenApp) statsLStemp.hints = statsLStemp.hints + 1;
                statsLStemp.dateUpdated = new Date();
                localStorage.setItem('stats', JSON.stringify(statsLStemp));
              }
            }
            //Check if word is WRONG
            else if (checkInputWordData.checkInputWord === 'WRONG') {
              props.disableButtonMainBodyFalse();
              props.modalCommenceMainBody('WRONG');
            }

            let existingLSKeyboardButtonsColor = localStorage.getItem('keyboardButtonsColorLS');
            existingLSKeyboardButtonsColor = JSON.parse(existingLSKeyboardButtonsColor || '[]');
            existingLSKeyboardButtonsColor.push(...keyboardButtonsColor);
            localStorage.setItem(
              'keyboardButtonsColorLS',
              JSON.stringify(existingLSKeyboardButtonsColor)
            );
            props.keyboardButtonsColorMainBody(keyboardButtonsColor);
          };
          checkInputWordAsyncFunction();
        });
    }
    return () => props.buttonClickedMainBody(false);
  });

  const inputWordRow = [];
  for (let i = 0; i < 6; i++) {
    let inputWordRowTemp = [];
    for (let j = 0; j < props.wordSelectedLength; j++) {
      inputWordRowTemp.push(
        <InputCell
          key={[i, j].join('')}
          inputCellValue={
            inputGridValue.length === 0
              ? ''
              : inputGridValue[i * props.wordSelectedLength + j].value
          }
          inputCellClass={
            inputGridValue.length === 0
              ? ''
              : inputGridValue[i * props.wordSelectedLength + j].class
          }
          inputAnswerWindowSize={inputAnswerWindowSize}
        />
      );
    }
    inputWordRow.push(inputWordRowTemp);
  }
  return (
    <div className="inputAnswerRowContainer">
      <div className="inputAnswer" id="inputAnswer" ref={refInputAnswer}>
        {inputWordRow.map((inputWordRowSingle, index) => {
          return (
            <div className="inputAnswerRow" key={index}>
              {inputWordRowSingle}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const InputCell = ({ inputCellValue, inputAnswerWindowSize, inputCellClass }) => {
  return (
    <input
      maxLength={1}
      readOnly
      disabled
      tabIndex={-1}
      value={inputCellValue}
      className={
        inputCellValue === ''
          ? ''
          : `inputAnswerDone${inputCellClass === '' ? '' : ` ${inputCellClass}`}`
      }
      style={
        isNaN(inputAnswerWindowSize)
          ? {}
          : {
              fontSize: `calc(0.2rem + ${inputAnswerWindowSize}vmin)`,
              width: `calc(0.5rem + ${inputAnswerWindowSize}vmin)`,
              height: `calc(0.5rem + ${inputAnswerWindowSize}vmin)`,
            }
      }
    ></input>
  );
};

export { InputAnswer };
