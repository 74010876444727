import React, { useEffect } from 'react';

const ShareMessageModal = ({ shareMessageModalState, setShareMessageModalState }) => {
  useEffect(() => {
    if (shareMessageModalState !== false)
      setTimeout(() => {
        setShareMessageModalState();
      }, 3000);
  });

  if (shareMessageModalState === 1)
    return (
      <div className="shareMessageTruemodal">
        <p>Share message copied to clipboard</p>
      </div>
    );
  else if (shareMessageModalState === 0)
    return (
      <div className="shareMessageFalsemodal">
        <p>Clipboard API not available</p>
      </div>
    );
  else return false;
};

export { ShareMessageModal };
