import React, { useEffect } from 'react';

const NewBiblidleMessageModal = ({
  newBiblidleMessageModalState,
  setNewBiblidleMessageModalStateFalse,
}) => {
  useEffect(() => {
    if (newBiblidleMessageModalState) {
      setTimeout(() => {
        localStorage.removeItem('gridValue');
        localStorage.removeItem('gameOver');
        localStorage.removeItem('keyboardButtonsColorLS');
        localStorage.removeItem('fromLS');
        localStorage.removeItem('hintSeen');
        window.location = window.location.href;
      }, 2000);
    }
  }, [newBiblidleMessageModalState]);

  return (
    newBiblidleMessageModalState && (
      <div className="newBiblidleMessageModalContainer">
        <div className="newBiblidleMessageModal">
          <p>Sorry, new Biblidle of the day has arrived!</p>
        </div>
      </div>
    )
  );
};

export { NewBiblidleMessageModal };
