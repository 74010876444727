import React, { useState } from 'react';
import './app.css';
import { Navbar } from './navbar';
import { MainBody } from './mainBody/mainBody.js';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-80Z5J7W0X5');
ReactGA.send('pageview');

const App = () => {
  const [win, setWin] = useState(false);
  const [shareWin, setShareWin] = useState([]);
  const [modalOnNavbar, setModalOnNavbar] = useState(false);
  const [hintSeenApp, setHintSeenApp] = useState(false);
  const setWinApp = (winData) => {
    setWin(winData);
  };
  const setShareWinApp = (shareWinData) => {
    setShareWin(shareWinData);
  };

  return (
    <div className="main">
      <Navbar
        win={win}
        shareWin={shareWin}
        setModalOnNavbarTrue={() => setModalOnNavbar(true)}
        setModalOnNavbarFalse={() => setModalOnNavbar(false)}
        hintSeenApp={hintSeenApp}
      />
      <MainBody
        win={win}
        setWinApp={setWinApp}
        setShareWinApp={setShareWinApp}
        modalOnNavbar={modalOnNavbar}
        setHintSeenAppTrue={() => setHintSeenApp(true)}
        hintSeenApp={hintSeenApp}
      />
    </div>
  );
};

export { App };
