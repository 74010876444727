import React, { useState, useEffect } from 'react';
import { InfoModal } from './infoModal';
import { SettingsModal } from './settingsModal';
import { ShareModal } from './shareModal';
import { StatsModal } from './statsModal';
import { ShareMessageModal } from './shareMessageModal';
import { NewBiblidleMessageModal } from './newBiblidleMessageModal';

const Navbar = (props) => {
  const [infoModalState, setInfoModalState] = useState(false);
  const [settingsModalState, setSettingsModalState] = useState(false);
  const [shareModalState, setShareModalState] = useState(false);
  const [statsModalState, setStatsModalState] = useState(false);
  const [shareMessageModalState, setShareMessageModalState] = useState(false);
  const [newBiblidleMessageModalState, setNewBiblidleMessageModalState] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('FirstTimeOnChristianWordle')) {
      setInfoModalState(true);
      localStorage.setItem('FirstTimeOnChristianWordle', 'true');
    }
  }, []);

  useEffect(() => {
    if (infoModalState || settingsModalState || shareModalState || statsModalState) {
      props.setModalOnNavbarTrue();
    } else if (!infoModalState && !settingsModalState && !shareModalState && !statsModalState) {
      props.setModalOnNavbarFalse();
    }
  }, [infoModalState, settingsModalState, shareModalState, statsModalState]);

  return (
    <div className="navbar">
      <button type="button" onClick={() => setSettingsModalState(true)}>
        <i className="fa-solid fa-gear"></i>
      </button>
      <button type="button" onClick={() => setInfoModalState(true)}>
        <i className="fa-solid fa-circle-info"></i>
      </button>
      <button type="button" onClick={() => setShareModalState(true)}>
        <i className="fa-solid fa-share-nodes"></i>
      </button>
      <button type="button" onClick={() => setStatsModalState(true)}>
        <i className="fa-solid fa-chart-bar"></i>
      </button>
      <h1 className="navbarTitle">Biblidle</h1>
      <SettingsModal
        settingsModalState={settingsModalState}
        setSettingsModalState={() => setSettingsModalState(false)}
      />
      <InfoModal
        infoModalState={infoModalState}
        setInfoModalState={() => setInfoModalState(false)}
      />
      <ShareModal
        shareModalState={shareModalState}
        setShareModalState={() => setShareModalState(false)}
        setShareModalStateTrue={() => setShareModalState(true)}
        win={props.win}
        shareWin={props.shareWin}
        setShareMessageModalStateTrue={() => setShareMessageModalState(1)}
        setShareMessageModalStateFalse={() => setShareMessageModalState(0)}
        setNewBiblidleMessageModalStateTrue={() => setNewBiblidleMessageModalState(true)}
        hintSeenApp={props.hintSeenApp}
      />
      <StatsModal
        statsModalState={statsModalState}
        setStatsModalState={() => setStatsModalState(false)}
        setStatsModalStateTrue={() => setStatsModalState(true)}
        win={props.win}
        setNewBiblidleMessageModalStateTrue={() => setNewBiblidleMessageModalState(true)}
        setShareModalStateTrue={() => setShareModalState(true)}
      ></StatsModal>
      <ShareMessageModal
        shareMessageModalState={shareMessageModalState}
        setShareMessageModalState={() => setShareMessageModalState(false)}
      />
      <NewBiblidleMessageModal
        newBiblidleMessageModalState={newBiblidleMessageModalState}
        setNewBiblidleMessageModalStateFalse={() => setNewBiblidleMessageModalState(false)}
      />
    </div>
  );
};

export { Navbar };
