import React, { useState, useEffect, useRef } from 'react';

const SettingsModal = ({ settingsModalState, setSettingsModalState }) => {
  const refModalCloseAnimation = useRef(null);
  const [darkMode, setDarkMode] = useState(false);
  const modalCloseFunction = () => {
    let fadeOutAnimationInterval = null;
    let opacityTemp = 1;
    clearInterval(fadeOutAnimationInterval);
    const fadeOutAnimation = () => {
      if (opacityTemp < 0.1) {
        clearInterval(fadeOutAnimationInterval);
        setSettingsModalState();
      } else {
        opacityTemp = opacityTemp - 0.1;
        refModalCloseAnimation.current.style.opacity = opacityTemp;
      }
    };
    fadeOutAnimationInterval = setInterval(fadeOutAnimation, 20);
  };
  const ifOutsideModalCloseFunction = (e) => {
    if (e.target === refModalCloseAnimation.current) modalCloseFunction();
  };

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('darkMode');
      setDarkMode(true);
    }
  }, []);

  const checkDarkModeToggle = (e) => {
    if (e.target.checked) {
      setDarkMode(true);
      document.documentElement.classList.add('darkMode');
    } else {
      setDarkMode(false);
      document.documentElement.classList.remove('darkMode');
    }
  };
  return (
    settingsModalState && (
      <div
        className="settingsModalContainer"
        ref={refModalCloseAnimation}
        onClick={ifOutsideModalCloseFunction}
      >
        <div className="settingsModal">
          <div className="settingsModalClose">
            <button type="button" onClick={modalCloseFunction}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="settingsModalHeader">
            <h1>Settings</h1>
          </div>
          <div className="settingModalContent">
            <div className="darkModeSettingsDropdownContent">
              <p>Dark Mode</p>
              <label className="darkModeSwitch">
                <input
                  type="checkbox"
                  id="darkModeToggle"
                  onChange={checkDarkModeToggle}
                  defaultChecked={darkMode}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="settingsModalFeedback">
              <p>Feedback</p>
              <a href="mailto:biblidlefeedback@gmail.com">Email</a>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export { SettingsModal };
