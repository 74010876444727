import React, { useState, useEffect, useRef } from 'react';
import { Keyboard } from './keyboard';
import { InputAnswer } from './inputAnswer';
import { Modals } from './modals';
import { Hints } from './hints';

const MainBody = (props) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [modalCommence, setModalCommence] = useState(false);
  const [keyboardButtonsColor, setKeyboardButtonsColor] = useState([]);
  const [wordSelectedLength, setWordSelectedLength] = useState(false);
  const [resizeInputAnswer, setResizeInputAnswer] = useState(false);
  const refMainBody = useRef(null);

  useEffect(() => {
    if (refMainBody.current.clientWidth < refMainBody.current.scrollWidth)
      setResizeInputAnswer(true);
  });

  useEffect(() => {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var currentTime = new Date();

    fetch('/wordLength', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({ timezone, currentTime }),
    })
      .then((result) => result.json())
      .then(({ wordSelectedLengthServer }) => setWordSelectedLength(wordSelectedLengthServer))
      .catch((err) => console.log(err));
  }, []);
  const buttonClickedMainBody = (buttonClickedData) => {
    setButtonClicked(buttonClickedData);
  };

  const disableButtonMainBodyTrue = () => {
    setDisableButton(true);
  };
  const disableButtonMainBodyFalse = () => {
    setDisableButton(false);
  };
  const modalCommenceMainBody = (modalCommenceNumber) => setModalCommence(modalCommenceNumber);
  const keyboardButtonsColorMainBody = (keyboardButtonColor) =>
    setKeyboardButtonsColor(keyboardButtonColor);

  return (
    <div className="mainBody" ref={refMainBody}>
      <Modals
        modalCommence={modalCommence}
        modalCommenceMainBody={modalCommenceMainBody}
        win={props.win}
      />
      <InputAnswer
        buttonClicked={buttonClicked}
        buttonClickedMainBody={buttonClickedMainBody}
        disableButtonMainBodyTrue={disableButtonMainBodyTrue}
        disableButtonMainBodyFalse={disableButtonMainBodyFalse}
        disableButton={disableButton}
        modalCommenceMainBody={modalCommenceMainBody}
        keyboardButtonsColorMainBody={keyboardButtonsColorMainBody}
        wordSelectedLength={wordSelectedLength}
        setWinApp={props.setWinApp}
        setShareWinApp={props.setShareWinApp}
        win={props.win}
        resizeInputAnswer={resizeInputAnswer}
        setResizeInputAnswerFalse={() => setResizeInputAnswer(false)}
        hintSeenApp={props.hintSeenApp}
      />
      <Hints setHintSeenAppTrue={props.setHintSeenAppTrue} win={props.win}></Hints>
      <Keyboard
        buttonClickedMainBody={buttonClickedMainBody}
        disableButtonMainBodyFalse={disableButtonMainBodyFalse}
        disableButton={disableButton}
        keyboardButtonsColor={keyboardButtonsColor}
        modalOnNavbar={props.modalOnNavbar}
      />
    </div>
  );
};

export { MainBody };
