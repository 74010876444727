import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';

const StatsModal = ({
  statsModalState,
  setStatsModalState,
  setStatsModalStateTrue,
  win,
  setNewBiblidleMessageModalStateTrue,
  setShareModalStateTrue,
}) => {
  const [timeLeft, setTimeLeft] = useState({ hours: '00', minutes: '00', seconds: '00' });
  const refModalCloseAnimation = useRef(null);
  const modalCloseFunction = () => {
    let fadeOutAnimationInterval = null;
    let opacityTemp = 1;
    clearInterval(fadeOutAnimationInterval);
    const fadeOutAnimation = () => {
      if (opacityTemp < 0.1) {
        clearInterval(fadeOutAnimationInterval);
        setStatsModalState();
      } else {
        opacityTemp = opacityTemp - 0.1;
        refModalCloseAnimation.current.style.opacity = opacityTemp;
      }
    };
    fadeOutAnimationInterval = setInterval(fadeOutAnimation, 20);
  };
  const ifOutsideModalCloseFunction = (e) => {
    if (e.target === refModalCloseAnimation.current) modalCloseFunction();
  };

  useEffect(() => {
    let fromLS = localStorage.getItem('fromLS');
    if (fromLS) {
      setStatsModalStateTrue();
    } else {
      if (win !== false) {
        setTimeout(() => {
          setStatsModalStateTrue();
        }, 3000);
      }
    }
  }, [win]);

  useEffect(() => {
    const timerFunction = setInterval(() => {
      let timerCurrentTimeStart = new Date();
      let timerCurrentTimeStartms = timerCurrentTimeStart.getTime();
      // console.log(timerCurrentTimeStart.toISOString());
      // console.log(timerCurrentTimeStartms);

      let timerTimeEnd = new Date(
        parseInt(timerCurrentTimeStart.getFullYear()),
        parseInt(timerCurrentTimeStart.getMonth()),
        parseInt(timerCurrentTimeStart.getDate() + 1)
      );

      let timerTimeEndms = timerTimeEnd.getTime();
      // console.log(timerTimeEnd.toISOString());
      // console.log(timerTimeEndms)

      let timerTimeLeftms = timerTimeEndms - timerCurrentTimeStartms;
      // console.log(timerTimeLeftms);
      let timerTimeLefthours = Math.floor(
        (timerTimeLeftms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let timerTimeLeftminutes = Math.floor((timerTimeLeftms % (1000 * 60 * 60)) / (1000 * 60));
      let timerTimeLeftseconds = Math.floor((timerTimeLeftms % (1000 * 60)) / 1000);
      if (timerTimeLefthours <= 9) timerTimeLefthours = `0${timerTimeLefthours}`;
      if (timerTimeLeftminutes <= 9) timerTimeLeftminutes = `0${timerTimeLeftminutes}`;
      if (timerTimeLeftseconds <= 9) timerTimeLeftseconds = `0${timerTimeLeftseconds}`;
      if (timerTimeLeftms >= 86399000) {
        setNewBiblidleMessageModalStateTrue();
      }
      setTimeLeft({
        hours: timerTimeLefthours,
        minutes: timerTimeLeftminutes,
        seconds: timerTimeLeftseconds,
      });
    }, 1000);

    return () => clearInterval(timerFunction);
  });

  const goToShareButtonFunction = () => {
    modalCloseFunction();
    setShareModalStateTrue();
  };

  let statsLStemp = localStorage.getItem('stats');
  statsLStemp = JSON.parse(statsLStemp);
  let statsBarData = [
    { tryNumber: '1', amount: 0 },
    { tryNumber: '2', amount: 0 },
    { tryNumber: '3', amount: 0 },
    { tryNumber: '4', amount: 0 },
    { tryNumber: '5', amount: 0 },
    { tryNumber: '6', amount: 0 },
  ];
  if (statsLStemp) {
    statsBarData = [
      { tryNumber: '1', amount: statsLStemp.noOf1, amountLabel: statsLStemp.noOf1 },
      { tryNumber: '2', amount: statsLStemp.noOf2, amountLabel: statsLStemp.noOf2 },
      { tryNumber: '3', amount: statsLStemp.noOf3, amountLabel: statsLStemp.noOf3 },
      { tryNumber: '4', amount: statsLStemp.noOf4, amountLabel: statsLStemp.noOf4 },
      { tryNumber: '5', amount: statsLStemp.noOf5, amountLabel: statsLStemp.noOf5 },
      { tryNumber: '6', amount: statsLStemp.noOf6, amountLabel: statsLStemp.noOf6 },
    ];
    statsBarData.forEach((item) => {
      if (item.amountLabel === 0) delete item.amountLabel;
    });
  }

  return (
    statsModalState && (
      <div
        className="statsModalContainer"
        ref={refModalCloseAnimation}
        onClick={ifOutsideModalCloseFunction}
      >
        <div className="statsModal">
          <div className="statsModalClose">
            <button type="button" onClick={modalCloseFunction}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="headerStatsModal">
            <h1>Stats</h1>
          </div>
          <div className="bodyStatsModal">
            <div className="mainStats">
              <div className="gamesCompletedMainStats">
                <p className="numberStats">{statsLStemp ? statsLStemp.GC : 0}</p>
                <p>Games</p>
                <p>Completed</p>
              </div>
              <div className="gamesWonMainStats">
                <p className="numberStats">
                  {statsLStemp
                    ? statsLStemp.GC === 0
                      ? '0%'
                      : `${Math.floor((statsLStemp.GW / statsLStemp.GC) * 100)}%`
                    : '0%'}
                </p>
                <p>Games</p>
                <p>Won</p>
              </div>
              <div className="currentStreakMainStats">
                <p className="numberStats">{statsLStemp ? statsLStemp.CS : 0}</p>
                <p>Current</p>
                <p>Streak</p>
              </div>
              <div className="highestStreakMainStats">
                <p className="numberStats">{statsLStemp ? statsLStemp.HS : 0}</p>
                <p>Highest</p>
                <p>Streak</p>
              </div>
              {/* <div className="hintsUsedMainStats">
                <p className="numberStats">{statsLStemp ? statsLStemp.hints : 0}</p>
                <p>Hints</p>
                <p>Used</p>
              </div> */}
            </div>
            <div className="hintsUsedStatsModal">
              <p>Hints used : {statsLStemp.hints}</p>
            </div>
            <div className="barGraphStats">
              <ResponsiveContainer
                width="100%"
                height={200}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <BarChart
                  data={statsBarData}
                  layout="vertical"
                  margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
                >
                  <Bar isAnimationActive={true} dataKey="amount" fill="green">
                    <LabelList
                      dataKey="amountLabel"
                      position="insideRight"
                      style={{ fill: 'white' }}
                    />
                  </Bar>
                  <YAxis
                    type="category"
                    dataKey="tryNumber"
                    tickLine={false}
                    dx={-5}
                    width={40}
                    stroke={
                      document.documentElement.classList.contains('darkMode') ? 'white' : 'black'
                    }
                    style={
                      document.documentElement.classList.contains('darkMode')
                        ? { fill: 'white' }
                        : { fill: 'black' }
                    }
                  />
                  <XAxis
                    type="number"
                    tick={false}
                    axisLine={false}
                    domain={[0, 'dataMax']}
                  ></XAxis>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="goToShareButton">
              <button type="button" onClick={goToShareButtonFunction}>
                Go To Share
              </button>
            </div>
            <p className="statsModalTimer">
              Next Biblidle in: {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export { StatsModal };
