import React, { useRef } from 'react';

const InfoModal = ({ infoModalState, setInfoModalState }) => {
  const refModalCloseAnimation = useRef(null);
  const modalCloseFunction = () => {
    let fadeOutAnimationInterval = null;
    let opacityTemp = 1;
    clearInterval(fadeOutAnimationInterval);
    const fadeOutAnimation = () => {
      if (opacityTemp < 0.1) {
        clearInterval(fadeOutAnimationInterval);
        setInfoModalState();
      } else {
        opacityTemp = opacityTemp - 0.1;
        refModalCloseAnimation.current.style.opacity = opacityTemp;
      }
    };
    fadeOutAnimationInterval = setInterval(fadeOutAnimation, 20);
  };
  const ifOutsideModalCloseFunction = (e) => {
    if (e.target === refModalCloseAnimation.current) modalCloseFunction();
  };

  return (
    infoModalState && (
      <div
        className="infoModalContainer"
        ref={refModalCloseAnimation}
        onClick={ifOutsideModalCloseFunction}
      >
        <div className="infoModal">
          <div className="infoModalClose">
            <button type="button" onClick={modalCloseFunction}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="headerInfoModal">
            <h1>Instructions</h1>
          </div>
          <div className="bodyInfoModal">
            <p>Try to guess the Biblidle of the day in six tries.</p>
            <p>
              Each guess word <b>can be of any length</b> but should be a <b>Bible related word</b>{' '}
              including names, places and other biblical or christianity related words.
            </p>
            <p className="beforeExampleBodyInfoModal">
              You can click on the <strong>Show Hint</strong> button to get a hint for the Biblidle
              word of the day. Your stats and share results will include that you used the hint.
            </p>
            <p>For example, if the Biblidle of the day is JESUS</p>
            <div className="exampleBodyInfoModal">
              <div className="greenExampleBodyInfoModal">
                <p>J</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>O</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>H</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>N</p>
              </div>
              <div></div>
            </div>
            <p>
              The green color shows that the letter 'J' is present in the Biblidle and is in the
              correct position
            </p>
            <div className="exampleBodyInfoModal">
              <div className="greyExampleBodyInfoModal">
                <p>P</p>
              </div>
              <div className="yellowExampleBodyInfoModal">
                <p>S</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>A</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>L</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>M</p>
              </div>
            </div>
            <p>
              The yellow color shows that the 'S' letter is present in the Biblidle but is in the
              wrong position
            </p>
            <div className="exampleBodyInfoModal">
              <div className="greyExampleBodyInfoModal">
                <p>H</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>O</p>
              </div>
              <div className="greyExampleBodyInfoModal">
                <p>P</p>
              </div>
              <div className="yellowExampleBodyInfoModal">
                <p>E</p>
              </div>
              <div></div>
            </div>
            <p>The dark grey color shows that those letters are not present in the Biblidle</p>
            <p className="afterExampleBodyInfoModal">A new Biblidle will be available each day!</p>
          </div>
        </div>
      </div>
    )
  );
};

export { InfoModal };
